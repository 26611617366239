.LandingPage {
    width: min(100%, 700px);
    box-sizing: border-box;
    -webkit-box-shadow: 3px 4px 12px -3px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 3px 4px 12px -3px rgba(0, 0, 0, 0.75);
    box-shadow: 3px 4px 12px -3px rgba(0, 0, 0, 0.75);
    background-color: rgba(255, 255, 255, 0.911);
    padding: 2rem;
    margin-top: 3rem;
    margin-inline: 1.5rem;
    .Landingpage_wrapper {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 30px;

        .left {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 2rem;

            h3 {
                font-size: 3.2rem;

                span {
                    display: block;
                    margin-left: 20px;
                    font-weight: 700;
                }
            }
        }

        .right {
            width: 100%;

            .LandingPage_list {
                list-style: none;
                width: 100%;
                align-items: center;

                .link_list {
 
                    border-bottom: 1px solid rgba(128, 128, 128, 0.274);
                    padding: 1rem .5rem;
                    cursor: pointer;
                    transition: all .2s ease-out;

                    &:hover {
                        background-color: rgba(241, 235, 235, 0.651);
                        transition: all .2s ease-in;
                    }

                    a {
                        text-decoration: none;
                        color: gray;
                        font-size: 1.5rem;
                        width: 100%;

                    }
                }
            }

        }
    }
}