.CustomerDetail {
    width: 100%;
   min-height: 100vh;
    background-color: white;
    display: flex;
    flex-direction: column;

    .CustomerDetail_top {
        display: flex;
        flex-direction: column;
        height: 26rem;
        background: url("../../assets/detail-bg.webp");
        background-position: center bottom;
        background-repeat: no-repeat;
        background-size: cover;

        .CustomerDetail_information {
            margin-top: auto;
            padding: 2rem;

            p {
                font-size: clamp(1.2rem, 2vw, 1.5rem);
                opacity: .5;
                margin-bottom: .5rem;
            }

            h3 {
                font-size: clamp(2rem, 3vw, 3rem);
                margin-bottom: 1rem;
            }

            .OrderNo {
                background-color: #99242d;
                width: 23rem;
                display: flex;
                justify-content: center;
                padding: .8rem 1rem;
                color: #fff;
                border-radius: 50rem;

            }
        }
    }

    .CustomerDetail_bottom {
        width: 100%;
        display: flex;
        flex-direction: column;
        padding: 2rem;

        .customer_info {
            display: flex;
            align-items: center;
            gap: 2rem;

        }

        .customer_info_Location {
            display: flex;
            flex-direction: column;
            align-items: baseline;
            margin: 1.5rem 0rem;

            .location_Info {
                display: flex;
                align-items: center;
                align-self: baseline;

            }
        }

        .Total_order {
            width: 100%;
            height: auto;

            .Qua {
                font-size: 2.5rem;
                font-weight: 600;
            }

            .total_asset {
                width: 100%;
                display: flex;
                align-items: center;

                flex-wrap: wrap;
                text-transform: uppercase;

                .asset {
                    display: flex;
                    align-items: center;
                    margin-right: 10rem;

                    .setting {
                        margin: 2rem;
                        border-radius: 1rem;
                        background-color: #ffd5d7;

                        .MuiSvgIcon-root {
                            font-size: 3.5rem !important;
                            margin: 2rem;
                            color: #99242d;
                            z-index: 1000;
                        }
                    }

                }
            }
        }
    }
}



.infoDiv {
    h3 {
        font-size: 2.5rem;
        font-weight: 600;
    }
}

.cimage {
    width: clamp(8rem, 8vw, 100%);
}



.location {
    font-size: 3.5rem;
}

.Customer_contact_detail {
    width: 40%;
    display: flex;
    text-transform: uppercase;
    flex-wrap: wrap;
    margin-bottom: 2rem;
    justify-content: space-between;
    gap: 1rem;

    .costomer_site {
        h3 {
            font-size: 2.2rem
        }

        h4 {
            font-size: 2rem;
            font-weight: 400
        }
    }

    .Customer_NO {
        h3 {
            font-size: 2.2rem
        }

        h4 {
            font-size: 2rem;
            font-weight: 400
        }
    }
}

.ImageUpload {
    width: 50%;

    h3 {
        margin-bottom: 2.5rem;
        font-weight: bold;
        font-size: 1.5rem;
    }

    .docs {
        background-color: #99242d;
        padding: 1rem clamp(3rem, 3vw, 9rem);
        border-radius: 50rem;
        color: #fff;
        font-weight: bold;

        &:active {

            border-width: 2px;
            border-style: solid;
            border-color: #86b7fe;
            box-shadow: 0 0 0 .4rem rgb(13 110 253 / 25%);

        }

    }
}