
.App {
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100vh;
  background: url("./assets/background.png");
  background-size: cover;
  background-position: center center;
  background-attachment: fixed;
  display: grid;
  place-items: center;
  color: #212529;
  overflow-x: hidden;

}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600;
}
a{
  text-decoration: none;
  
}
ul{
  list-style: none;
}